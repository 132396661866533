const logo = document.querySelector('#logo');
const path = '/wp-content/themes/sitalav2/dist/img/';

const swiperAccueil = new Swiper('#sitala-carousel', {
  slidesPerView: 1,
  effect: 'fade',
  direction: 'horizontal',
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  pagination: {
    el: '.sitala-main-carousel__pagination',
    clickable: true,
  },
});

const checkActiveSlide = () => {
  const currentSlide = swiperAccueil.slides[swiperAccueil.activeIndex];
  const logoIsWhite = currentSlide.getAttribute('data-logo-white') === 'true';

  logoIsWhite
    ? (logo.src = `${path}logo-sitala-bis.svg`)
    : (logo.src = `${path}logo-sitala.svg`);
};

swiperAccueil.on('slideChange', checkActiveSlide);
checkActiveSlide();

window.addEventListener('scroll', () => {
  const st = window.pageYOffset || document.documentElement.scrollTop;
  st > 20 ? (logo.src = `${path}logo-sitala.svg`) : checkActiveSlide();
});
